/* You can add global styles to this file, and also import other style files */

/* @import url("https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"); */

/* Made with love by Mutiullah Samim*/

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Jost:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

/* @import "./assets/css/_materialFullCalendar.css"; */

/* @import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css"; */

@import "~ngx-toastr/toastr";
@import "tippy.js/dist/tippy.css";
@import "tippy.js/dist/backdrop.css";
@import "tippy.js/animations/shift-away.css";
@import "tippy.js/themes/light.css";
@import "tippy.js/dist/svg-arrow.css";
@import "tippy.js/animations/scale-extreme.css";
@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";

/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";
@import "~@angular/cdk/overlay-prebuilt.css";
.stripe-1 {
  color: white;
  background: repeating-linear-gradient(
    45deg,
    #606dbc,
    #606dbc 10px,
    #465298 10px,
    #465298 20px
  );
}

@media (max-width: 767.98px) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: block;
    text-align: center;
  }
  .fc-header-toolbar .fc-toolbar-chunk {
    display: block;
  }
}

.container-fluid {
  padding: 30px !important;
}

@media only screen and (max-width: 1500px) {
  .hidden-mobile {
    display: none;
  }
  .show-full-width {
    width: 100%;
  }
  .container-fluid {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

@media only screen and (max-width: 1200px) {
  button.fc-downloadPlan-button {
    font-size: 0 !important;
    width: 40px;
  }
  button.fc-publishPlanButton-button {
    font-size: 0 !important;
    width: 40px;
  }
  button.fc-selectTemplate-button {
    font-size: 0 !important;
    width: 40px;
  }
  button.fc-autoFillPlanButton-button {
    font-size: 0 !important;
    width: 40px;
  }
  button.fc-savePlanTemplateButton-button {
    font-size: 0 !important;
    width: 40px;
  }
  button.fc-deletePlanButton-button {
    font-size: 0 !important;
    width: 40px;
  }
}

@media only screen and (max-width: 780px) {
  .hidden-small-mobile {
    display: none !important;
  }
  .fc-scroller-harness {
    width: auto !important;
  }
}

@media (max-width: 767.98px) {
  .container-fluid {
    padding: 0px;
  }
  .col-12 {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.mat-mdc-row {
  border-bottom-width: 10px !important;
  border-bottom-color: #f3f3f3 !important;
  background: #fbfbfb !important;
  margin-bottom: 10px !important;
  border-radius: 19px !important;
}

.mat-mdc-table {
  border-radius: 19px !important;
  overflow: hidden !important;
  border-collapse: separate !important;
  border-spacing: 0 4px !important;
  background-color: transparent !important;
}

.fc .fc-scrollgrid {
  border-collapse: collapse !important;
}

/* .mat-mdc-row:hover {
  cursor: pointer;
  transform: scale(1.005);
} */

.fa-clickable {
  cursor: pointer;
  outline: none;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.departement-employee-list {
  margin-bottom: 1px !important;
  background-color: #175873;
  height: 62px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-right: 0px !important;
}

/* set color of svg to red in the */

embed.custom-icon {
  filter: invert(14%) sepia(100%) saturate(4748%) hue-rotate(305deg)
    brightness(89%) contrast(109%);
  /* https://codepen.io/sosuke/pen/Pjoqqp */
}

.audio-progress-bar.mat-mdc-progress-bar {
  height: 3px;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */

.audio-progress-bar .mat-progress-bar-fill::after {
  background-color: #4ccaf175 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */

.audio-progress-bar .mat-progress-bar-buffer {
  background-color: #4ccaf177 !important;
  /*  add transperant */
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */

.audio-progress-bar .mat-progress-bar-background {
  animation: none;
  background-color: #eceff1;
  fill: #eceff1;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */

mat-progress-bar .mat-progress-bar-buffer {
  background: #e0b2bd6d;
}

.progress-container {
  z-index: 1;
  position: fixed;
  top: 0px;
  /* Adjust to desired height */
  width: 100%;
}

.stripes {
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    #bbbcebd2 10px,
    #ededed33 20px
  );
}

.stripes:hover {
  background: transparent;
}

.vertical-line-draft {
  border-left: solid thick var(--event-vertical-line-draft) !important;
  border-right: transparent !important;
  border-top: transparent !important;
  border-bottom: transparent !important;
  border-width: 8px !important;
  border-radius: 6px !important;
  height: 45px;
}

.vertical-line-accepted {
  border-left: solid thick var(--event-vertical-line-accepted) !important;
  border-right: transparent !important;
  border-top: transparent !important;
  border-bottom: transparent !important;
  border-width: 8px !important;
  border-radius: 6px !important;
  height: 45px;
}

.vertical-line-availability {
  border-left: solid thick var(--event-vertical-line-accepted) !important;
  border-right: transparent !important;
  border-top: transparent !important;
  border-bottom: transparent !important;
  border-width: 6px !important;
  border-radius: 6px !important;
  height: 35px;
}

.vertical-line-time-recordings {
  border-left: solid thick var(--event-vertical-line-time-recording-work) !important;
  border-right: transparent !important;
  border-top: transparent !important;
  border-bottom: transparent !important;
  border-width: 8px !important;
  border-radius: 6px !important;
  height: 45px;
}

.vertical-line-sick {
  border-left: solid thick var(--event-sick) !important;
  border-right: transparent !important;
  border-top: transparent !important;
  border-bottom: transparent !important;
  border-width: 8px !important;
  border-radius: 6px !important;
  height: 45px;
}

.vertical-line-holiday {
  border-left: solid thick var(--event-holiday) !important;
  border-right: transparent !important;
  border-top: transparent !important;
  border-bottom: transparent !important;
  border-width: 8px !important;
  border-radius: 6px !important;
  height: 45px;
}

.vertical-line-vacation {
  border-left: solid thick var(--event-vacation) !important;
  border-right: transparent !important;
  border-top: transparent !important;
  border-bottom: transparent !important;
  border-width: 8px !important;
  border-radius: 6px !important;
  height: 45px;
}

.vertical-line-schedule-requirement {
  border-left: solid thick var(--event-vertical-line-schedule-requirement) !important;
  border-right: transparent !important;
  border-top: transparent !important;
  border-bottom: transparent !important;
  border-width: 8px !important;
  border-radius: 6px !important;
  height: 45px;
}

.vertical-line-rejected {
  border-left: solid thick var(--event-vertical-line-rejected) !important;
  border-right: transparent !important;
  border-top: transparent !important;
  border-bottom: transparent !important;
  border-width: 8px !important;
  border-radius: 6px !important;
  height: 45px;
}

.vertical-line-open-shift {
  border-left: solid thick var(--event-vertical-line-open-shift) !important;
  border-right: transparent !important;
  border-top: transparent !important;
  border-bottom: transparent !important;
  border-width: 8px !important;
  border-radius: 6px !important;
  height: 45px;
}

.horizental-line {
  border-top: solid thick #0e4269 !important;
  border-left: transparent !important;
  border-right: transparent !important;
  border-bottom: transparent !important;
  border-width: 12px !important;
  border-radius: 10px !important;
  height: auto;
}

.fc-addAbsenceButton-button {
  margin-right: 20px !important;
  background-color: cornflowerblue !important;
}

.fc-button {
  height: 34px !important;
  color: var(--fc-button-background-color) !important;
  text-decoration: none;
  font-family: "Open Sans", sans-serif !important;
  font-size: 12px !important;
  text-align: center;
  margin-left: 2px !important;
  line-height: 28px;
  display: inline-block;
  position: relative;
  border-radius: 10px !important;
  background-image: linear-gradient(#ffffffe6 45%, #f8f8f8f6 55%);
  box-shadow: 0 2px 2px #88888893;
  border-color: white !important;
  margin: 5px !important;
  transition: all 0.5s ease-in-out;
}

.fc-button:hover {
  /* background-color: #7d98b6b6 !important; */
  background-image: linear-gradient(#c2d4dd 49%, #c8d7df 51%);
  color: #03324c;
}

thead,
.fc-timeline-header {
  background-color: var(--fc-timeline-header);
  color: rgba(255, 255, 255, 0.233);
}

th[role="presentation"] {
  background-color: transparent !important;
}

a.fc-daygrid-day-number {
  color: black;
}

.example-section {
  display: flex;
  align-content: center;
  align-items: center;
  height: 60px;
}

.example-margin {
  margin: 0 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */

mat-checkbox.cross .mat-checkbox-background {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23ff4081'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'/%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
}

/* Remove the header border from Schedule */

.fc td,
.fc th {
  border-color: var(--body-background-color) !important;
  border-width: 3px !important;
  border-radius: 5px !important;
  padding: 0 !important;
  vertical-align: top !important;
  color: var(--font-color) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Open Sans", sans-serif !important;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
}

td.fc-timeline-lane {
  background-color: white !important;
  border-radius: 10px !important;
  border-width: 5px !important;
}

td.fc-datagrid-cell {
  border-width: 5px !important;
  background-color: white !important;
  border-radius: 10px !important;
}

.current-time {
  background-color: rgba(20, 20, 20, 0.1);
  color: #181818;
  position: relative;
  cursor: pointer;
  padding-right: 5px;
  text-align: right;
}

.time-line-header-day {
  cursor: pointer;
  font-weight: 700;
}

.time-line-header-day:hover {
  color: var(--fc-button-background-color);
}

.time-line-header-day-add-employee {
  background-color: #abb3c08a;
  cursor: pointer;
  margin-left: 20px;
  padding: 3px;
  border-radius: 8px;
}

.time-line-header-day-add-employee:hover {
  background-color: #6580b08a;
  color: var(--fc-button-background-color);
  font-weight: 700;
}

.time-line-header-day-warning {
  color: #ff8307;
  transition: color 1s;
}

.time-line-header-day-warning:hover {
  color: #ff3407;
  font-size: larger;
  transition: color 1s;
}

.time-line-header-day-warning:focus,
.time-line-header-day-warning:active {
  transition: none;
}

.dailycolumncutting td {
  width: 100%;
}

.dailycolumncutting td.hovered {
  text-align: center;
  background-color: #ff000055;
}

.fc-datagrid-cell-cushion.fc-scrollgrid-sync-inner {
  padding-top: 5px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
}

.fc-datagrid-expander.fc-datagrid-expander-placeholder {
  display: none !important;
}

span.fc-datagrid-expander-placeholder span.fc-icon {
  display: none !important;
  background-color: red;
}

td.fc-datagrid-cell.fc-resource
  div.fc-datagrid-cell-frame
  div.fc-datagrid-cell-cushion
  span.fc-icon {
  display: none !important;
}

.fc-datagrid-cell-main {
  font-size: large;
}

#progress-spinner {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

#middle-circle {
  position: absolute;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  background-color: rgb(248, 248, 248);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
  font-weight: 500;
}

a.fc-timegrid-axis-cushion,
a.fc-col-header-cell-cushion,
a.fc-timeline-slot-cushion {
  color: var(--fc-timeline-slot-cushion) !important;
  text-decoration: none !important;
  font-size: 15px;
  padding-left: 0px;
  padding-right: 0px;
}

.fc-past {
  background-color: rgb(255, 240, 246);
}

/* .fc-day:hover{background:lightblue;cursor: pointer;} */

/* .fc-content:hover{background:lightblue;cursor: pointer;} */

.fc-content td:hover {
  background: #adf4fa;
}

/* .fc-event:hover {
  border-color: #1c7d87;
}
.fc-event:hover .fc-content {
  color: #1c7d87;
} */

canvas {
  cursor: pointer !important;
}

.fc-event {
  font-size: 13px !important;
}

.fc-event-main-frame {
  display: flex;
  flex-wrap: wrap;
}

.mat-expansion-panel-body {
  padding: 0px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */

.mat-button-toggle-label-content {
  line-height: 24px !important;
}

.navbar-nav {
  height: 100% !important;
}

.fc-timegrid-slot-label-cushion {
  font-weight: 900 !important;
}

@media print {
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
  }
}

.newgroup {
  opacity: 0.4;
}

.pointer:hover {
  opacity: 1;
  text-shadow: 2px 0 0 rgb(204, 191, 191);
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

.positive {
  background-color: #e5f2e5;
}

.negative {
  background-color: #fff9f9;
}

/* .md-drppicker {
    background-color: #4e73df;
  } */

mat-form-field {
  width: 100%;
}

.relativec {
  position: relative !important;
}

mat-progress-spinner {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 40%;
  left: 40%;
  z-index: 10;
}

.mat-mdc-dialog-surface {
  padding: 0px !important;
  border-radius: 25px !important;
}

.modal-footer {
  padding-top: 15px;
  border-color: transparent !important;
}

.modal-dialog {
  max-width: 100%;
  margin: 30px;
}

@media (max-width: 750px) {
  .modal-dialog {
    margin: 15px !important;
    max-width: 100%;
  }

  .p-5 {
    padding: 1rem 0 3rem 0 !important;
  }
}

.modal-header {
  border-color: transparent;
  margin: 10px;
  padding-bottom: 15px;
}

.expense-font {
  color: #f7184d !important;
}

.income-font {
  color: #4caf50 !important;
}

html,
body {
  height: 100% !important;
  padding-top: 25px;
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  background-color: var(--body-background-color);
}

.cdk-global-scrollblock {
  top: 0 !important;
  overflow: hidden;
  width: 100% !important;
}

.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
  top: 5px !important;
}

a.nav-link {
  margin-left: 10px;
}

.navbar-default {
  background-color: var(--navbar-default-background-color);
  border-color: #455a64;
  min-height: 55px;
}

.navbar-disabled {
  pointer-events: none;
  opacity: 0.5;
  /* Oder ein anderer Stil, um zu zeigen, dass sie deaktiviert ist */
}

/* Title */

.navbar-default .navbar-brand {
  padding: 0;
  color: black;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: var(--navbar-default-active-color);
}

div#show-employee-work-shifts {
  position: relative;
}

div#show-employee-work-shifts img.cover {
  position: absolute;
  /* position in top left of #tobecovered */
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  left: 40%;
  text-align: center;
  opacity: 0.3;
  width: 90px;
}

a.fc-daygrid-day-number {
  text-decoration: none !important;
  font-weight: 800;
}

.blink {
  animation: blinkMe 4s linear infinite;
}

@keyframes blinkMe {
  0% {
    opacity: 0.1;
    rotate: 45deg;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.1;
  }
}

.navbar-default .navbar-nav > li > a {
  color: var(--font-color);
}

a.nav-link.dropdown-toggle {
  color: var(--font-color);
}

a.nav-link.dropdown-toggle:hover {
  color: rgb(175, 231, 233);
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: rgb(175, 231, 233);
}

/* Caret */

.navbar-default .navbar-nav > .dropdown > a .caret {
  border-top-color: #777;
  border-bottom-color: #777;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */

.button-toggle-delete.mat-button-toggle-checked {
  background-color: #d32f2f;
  color: white;
}

button.fc-deletePlanButton-button::before {
  display: inline-block;
  content: " ";
  background-image: url("./assets/img/delete.png");
  filter: invert(12%) sepia(27%) saturate(4736%) hue-rotate(179deg)
    brightness(95%) contrast(98%);
  background-size: 18px 18px;
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

button.fc-publishPlanButton-button::before {
  display: inline-block;
  content: " ";
  background-image: url("./assets/img/publish.png");
  filter: invert(12%) sepia(27%) saturate(4736%) hue-rotate(179deg)
    brightness(95%) contrast(98%);
  background-size: 18px 18px;
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

button.fc-autoFillPlanButton-button::before {
  display: inline-block;
  content: " ";
  background-image: url("./assets/img/smart-fill.png");
  filter: invert(12%) sepia(27%) saturate(4736%) hue-rotate(179deg)
    brightness(95%) contrast(98%);
  background-size: 18px 18px;
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

button.fc-downloadPlan-button::before {
  display: inline-block;
  content: " ";
  background-image: url("./assets/img/icons8-ordner-_downloads_-100.png");
  filter: invert(12%) sepia(27%) saturate(4736%) hue-rotate(179deg)
    brightness(95%) contrast(98%);
  background-size: 18px 18px;
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

button.fc-selectTemplate-button::before {
  display: inline-block;
  content: " ";
  background-image: url("./assets/img/template.png");
  filter: invert(12%) sepia(27%) saturate(4736%) hue-rotate(179deg)
    brightness(95%) contrast(98%);
  background-size: 18px 18px;
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

button.fc-savePlanTemplateButton-button::before {
  display: inline-block;
  content: " ";
  background-image: url("./assets/img/icons8-save-64.png");
  filter: invert(12%) sepia(27%) saturate(4736%) hue-rotate(179deg)
    brightness(95%) contrast(98%);
  background-size: 18px 18px;
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

button.fc-addAbsenceButton-button::before {
  display: inline-block;
  content: "";
  background-image: url("./assets/img/absence.png");
  filter: invert(12%) sepia(27%) saturate(4736%) hue-rotate(179deg)
    brightness(95%) contrast(98%);
  background-size: 18px 18px;
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

button.fc-absenceMultiMonthYear-button::before {
  display: inline-block;
  content: "";
  background-image: url("./assets/img/365.png");
  filter: invert(12%) sepia(27%) saturate(4736%) hue-rotate(179deg)
    brightness(95%) contrast(98%);
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

button.fc-absenceDayGridMonth-button::before {
  display: inline-block;
  content: "";
  background-image: url("./assets/img/calendar3.png");
  filter: invert(12%) sepia(27%) saturate(4736%) hue-rotate(179deg)
    brightness(95%) contrast(98%);
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

/* Gemeinsame Stile für beide Buttons */

button.fc-employeeTimeGridButton-button,
button.fc-employeeCustomMonthButton-button,
button.fc-addAbsenceButtonEmployee-button,
button.fc-addAvailabilityButton-button {
  color: white !important;
  background-image: linear-gradient(
    var(--primary-color-blue) 45%,
    var(--primary-color-blue-transparent) 55%
  ) !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  box-shadow: 0 2px 2px #09889bbd !important;
}

/* Gemeinsame Stile für die ::before-Pseudo-Elemente */

button.fc-employeeTimeGridButton-button::before,
button.fc-employeeCustomMonthButton-button::before,
button.fc-addAbsenceButtonEmployee-button::before,
button.fc-addAvailabilityButton-button::before {
  display: inline-block;
  content: "";
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%)
    contrast(100%);
  background-size: 19px 19px;
  height: 19px;
  width: 19px;
  margin-right: 10px;
}

/* Spezifische Hintergrundbilder für die ::before-Pseudo-Elemente */

button.fc-addAbsenceButtonEmployee-button::before {
  background-image: url("./assets/img/absence.png");
}

button.fc-addAvailabilityButton-button::before {
  background-image: url("./assets/img/prufen.png");
}

button.fc-employeeTimeGridButton-button::before {
  background-image: url("./assets/img/week.png");
}

button.fc-employeeCustomMonthButton-button::before {
  background-image: url("./assets/img/calendar3.png");
}

.fc-header-toolbar {
  margin-bottom: 10px !important;
}

.mat-datepicker-toggle-default-icon {
  color: var(--primary-color-blue) !important;
}

.mat-calendar-table-header th {
  color: white !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 0.5rem !important;
}

.mat-calendar-table-header {
  background-color: rgb(3 161 221) !important;
}

.mat-calendar-table-header-divider {
  background-color: white !important;
}

.mat-calendar-body-selected {
  background-color: var(--primary-color-blue) !important;
  color: white !important;
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgb(6, 6, 6) !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--primary-color-blue) !important;
}

/* .fc-deletePlanButton-button{
  background-color: #af2d63d7 !important;
}*/

.card-header {
  background-color: white !important;
  color: var(--font-color) !important;
  border-radius: 1.325rem 1.325rem 0 0 !important;
  padding-top: 20px;
}

.card {
  border: 1px solid white;
  border-radius: 1.325rem;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  left: 50%;
}

.navbar-nav > li {
  float: left;
  position: relative;
}

.fixed-top .navbar-nav .active a::after {
  border-bottom: 3px solid var(--primary-color-blue);
  bottom: -5px;
  content: " ";
  left: 0;
  position: absolute;
  right: 0;
}

.content-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

nav {
  background-color: #fff;
  border-radius: 0.625rem;
}

.btn-primary {
  background: linear-gradient(
    to right,
    var(--primary-color-blue),
    var(--primary-color-blue-dark)
  );
  border: none;
  border-radius: 8px;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.btn-primary:hover {
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.btn-success {
  background: linear-gradient(
    to right,
    var(--success-color),
    var(--success-color-dark)
  );
  border: none;
  border-radius: 8px;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.btn-success:hover {
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.btn-danger {
  background: linear-gradient(
    to right,
    var(--danger-color),
    var(--danger-color-dark)
  );
  border: none;
  border-radius: 8px;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.btn-danger:hover {
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.btn-outline-secondary {
  background: transparent;
  border: 2px solid var(--secondary-color);
  border-radius: 8px;
  color: var(--secondary-color);
  transition: background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.btn-outline-secondary:hover {
  background: linear-gradient(
    to right,
    var(--secondary-color),
    var(--secondary-color-dark)
  );
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-outline-secondary:active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-outline-secondary:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(108, 117, 125, 0.5);
}

.btn-outline-danger {
  background: transparent;
  border: 1px solid var(--danger-color);
  border-radius: 8px;
  color: var(--danger-color);
  transition: background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.btn-outline-danger:hover {
  background: linear-gradient(
    to right,
    var(--danger-color),
    var(--danger-color-dark)
  );
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-outline-danger:active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-outline-danger:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5);
}

.btn-outline-success {
  background: transparent;
  border: 1px solid var(--success-color);
  border-radius: 8px;
  color: var(--success-color);
  transition: background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.btn-outline-success:hover {
  background: linear-gradient(
    to right,
    var(--success-color),
    var(--success-color-dark)
  );
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-outline-success:active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-outline-success:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5);
}

/* Color Variables */

:root {
  --primary-color-blue: #4ccaf1;
  --primary-color-blue-dark: #3e95beca;
  --primary-color-blue-transparent: #4ccaf1e6;
  --font-color: #7a7a7a;
  --pimery-color: #455a64;
  --secondary-color: #e5f5f8;
  --body-background-color: #f3f5fa;
  --table-header-font-color: #7a7a7a;
  --fc-button-background-color: #03324c;
  --fc-timeline-header: #e5e5e5b9;
  --fc-timeline-slot-cushion: #121111b9;
  --fc-timeline-border-color: #6c757d29;
  --navbar-default-background-color: white;
  --navbar-default-active-color: var(--primary-color-blue);
  /* Event Color */
  --event-vertical-line-draft: #0e4269;
  --event-draft: #f1c13215;
  --event-vertical-line-accepted: #00695c;
  --event-accepted: #00695c65;
  --event-vertical-line-rejected: #882709;
  --event-rejected: #88270965;
  --event-vertical-line-open-shift: #424242;
  --event-rejected: #42424265;
  --email-status-color-accepted: #d9eec7;
  --email-status-color-not-accepted: #f2e1c7;
  --event-vertical-line-time-recording-work: #7765e0;
  --event-vertical-line-schedule-requirement: #0d47a1;
  --event-vacation: #d37506;
  --event-sick: #9f2b00;
  --event-sick-light: #f2c2b3; /* Light version of sick color */
  --event-vacation-light: #f8d5b0; /* Light version of vacation color */
  --event-holiday: #b2ebf2;
  --event-holiday-transparent: #b2ebf249;

  --primary-color: #3f51b5;
  --primary-gradient: linear-gradient(45deg, #3f51b5, #1e88e5);
  --hover-gradient: linear-gradient(45deg, #1e88e5, #3f51b5);
  --selected-gradient: linear-gradient(
    45deg,
    #80b0fe,
    #a7e0fb
  ); /* Hellblauer Farbverlauf */
  --border-width: 2px;
  --border-radius: 12px;
  --transition-speed: 0.3s;
  --default-box-shadow: rgba(0, 0, 0, 0.1);
  --selected-box-shadow: rgba(63, 81, 181, 0.3);
  --hover-box-shadow: rgba(0, 0, 0, 0.15);

  --title-color-blue: #2c3e50;

  --success-color: #28a745;
  --success-color-dark: #218838;

  --danger-color: #dc3545;
  --danger-color-dark: #c82333;

  --secondary-color: #6c757d;
  --secondary-color-dark: #5a6268;
}

tr.fc-timeline-header-row {
  background-color: #ffffffd7 !important;
  font-size: 10px !important;
}

.fc-timeline-header {
  font-size: 10px !important;
}

.fc-toolbar-title {
  font-size: 18px !important;
  padding-left: 5px !important;
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.event-animation-scale {
  animation: scale 0.7s ease-in-out;
  animation-iteration-count: 1;
}

.pull-to-refresh {
  top: 0;
  left: 0;
  position: fixed;
  height: 70px;
  /* Ensure this is enough to make the pull-to-refresh visible */
  width: 100%;
  z-index: 5;
  /* Ensure this is behind other clickable elements */
  /* Remove pointer-events: none; */
}

.pull-to-refresh p {
  /* No need for pointer-events: all; */
}

.mat-mdc-select {
  min-width: 180px;
}

.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #4caf50;
  --mdc-switch-selected-handle-color: #4caf50;
  --mdc-switch-selected-hover-state-layer-color: #4caf50;
  --mdc-switch-selected-pressed-state-layer-color: #4caf50;
  --mdc-switch-selected-focus-handle-color: #2e7d32;
  --mdc-switch-selected-hover-handle-color: #2e7d32;
  --mdc-switch-selected-pressed-handle-color: #2e7d32;
  --mdc-switch-selected-focus-track-color: #4caf50;
  --mdc-switch-selected-hover-track-color: #4caf50;
  --mdc-switch-selected-pressed-track-color: #4caf50;
  --mdc-switch-selected-track-color: #4caf50;
}

.mat-toolbar.mat-primary {
  background: #3e96be !important;
  color: #fff;
}

.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #3e96be !important;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}

.a {
  position: relative;
  width: 54px;
  height: 20px;
  float: right;
}

.b {
  position: absolute;
  width: fit-content;
}

.c {
  position: absolute;
  width: fit-content;
  display: none;
  top: 0;
  left: 0;
}

.a:hover .b {
  display: none;
  /* Verstecke B beim Hover über A */
}

.a:hover .c {
  display: block;
  /* Zeige C beim Hover über A */
}

.manager {
  color: #f18585;
}

.team-leader {
  color: #7e9bdb;
}

.inactive-row {
  background-color: #bebebe33 !important;
}

/* Style for the checked thumb */

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #4ccaf1;
  /* Custom thumb color */
}

/* Style for the checked bar */

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #4ccaf1;
  /* Custom bar color */
}

/* Optional: Style for the unchecked thumb (default state) */

.mat-slide-toggle-thumb {
  background-color: #ccc;
  /* Custom color for the default thumb */
}

/* Optional: Style for the unchecked bar (default state) */

.mat-slide-toggle-bar {
  background-color: #aaa;
  /* Custom color for the default bar */
}

ion-content {
  width: calc(100% + 15px);
}

ion-content::part(scroll) {
  padding-right: 15px;
}

.fadeInUpwards {
  animation: fadeInUpwards 0.8s ease-in-out;
}

@keyframes fadeInUpwards {
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.mat-mdc-tab-body-wrapper {
  padding-top: 30px !important;
}

.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

.page-title {
  font-size: 1.75em;
  /* Etwas kleinere Größe für weniger Dominanz */
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--title-color-blue);
  letter-spacing: 0.5px;
}

.fc .fc-timeline-side .fc-resource-area .fc-resource-area-header {
  min-width: 200px !important;
}

.required-label {
  position: relative;
}

.required-label::after {
  content: "*";
  color: rgba(246, 43, 43, 0.716);
  position: absolute;
  margin-left: 4px;
  top: 0;
  font-size: 0.9em;
}

.weekend-day {
  background-color: #d7fc6a18;
}

.holiday-day {
  background-color: var(--event-holiday-transparent);
}

.mdc-checkbox__checkmark {
  border-color: var(--primary-color-blue) !important;
  background-color: var(--primary-color-blue) !important;
}

.mdc-checkbox__background {
  border-color: var(--primary-color-blue) !important;
}

.custom-dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0; /* Ensure no margin to avoid shifting */
  max-height: 85vh;
  max-width: 90vw;
  scrollbar-width: thin; /* Makes the scrollbar thinner in Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.193) transparent;
  padding-top: 20px; /* Space above the modal content */
  z-index: 1050;
}

.custom-dialog-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light background for the track */
  border-radius: 10px; /* Rounded edges for a softer look */
}

.custom-dialog-container::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded edges for the thumb */
}

.custom-dialog-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover for better visibility */
}

/* tooltip */

.mdc-tooltip__surface {
  position: relative !important;
  background: linear-gradient(to right, #cbdfff, #d3effc) !important;

  padding: 15px !important;
  border-radius: 12px !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important; /* 3D-Effekt durch Schatten */
  transform-style: preserve-3d !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important; /* Animation */
  color: #0a0a0a !important; /* Textfarbe für besseren Kontrast */
  overflow: hidden !important; /* Für pseudo-Elemente */
  font-weight: 500 !important;
  animation: tooltip-appear 0.3s ease-out !important;
  /* text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important; */
  font-size: 14px !important;
}

.mdc-tooltip__surface:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3) !important; /* Intensiverer Schatten bei Hover */
}

.mdc-tooltip__surface::before {
  content: "" !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: inherit !important;
  filter: blur(10px) !important; /* Unschärfe für einen Glow-Effekt */
  z-index: -1 !important;
}

.mdc-tooltip__surface::after {
  content: "" !important;
  position: absolute !important;
  bottom: -20px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 40px !important;
  height: 40px !important;
  background: #fff !important;
  border-radius: 50% !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2) !important; /* Schatten für 3D-Effekt */
  z-index: -1 !important;
  display: none !important;
}

.mdc-tooltip__surface .tooltip-content {
  font-size: 16px !important;
  text-align: center !important;
  line-height: 1.5 !important;
}

.mdc-tooltip__surface .tooltip-title {
  font-weight: bold !important;
  margin-bottom: 10px !important;
  font-size: 20px !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
}

.mdc-tooltip__surface .close-button {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  background: rgba(255, 255, 255, 0.3) !important;
  border: none !important;
  font-size: 18px !important;
  cursor: pointer !important;
  color: #000 !important;
  border-radius: 50% !important;
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: background 0.2s ease !important;
}

.mdc-tooltip__surface .close-button:hover {
  background: rgba(255, 255, 255, 0.5) !important;
}

@keyframes tooltip-appear {
  from {
    opacity: 0;
    transform: translateY(10px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #087d87 !important;
  color: #fff;
}

.mat-step-header {
  border-radius: 20px !important;
}

.skeleton-loader {
  color: #e0e0e0;
  animation: shimmer 0.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -100px 0;
  }
  100% {
    background-position: 100px 0;
  }
}

.alert-info {
  /* Bestehende Bootstrap-Variablen beibehalten */
  --bs-alert-color: #055160 !important; /* Dunkleres Blau */
  --bs-alert-bg: linear-gradient(
    135deg,
    #cff4fc,
    #a0e1f5
  ) !important; /* Leichter Farbverlauf */
  --bs-alert-border-color: #b6effb !important;

  /* Hintergrund mit Farbverlauf */
  background: var(--bs-alert-bg) !important;

  /* Border anpassen */
  border: 1px solid var(--bs-alert-border-color) !important;

  /* 3D-Effekt durch Box-Shadow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;

  /* Abgerundete Ecken für ein moderneres Aussehen */
  border-radius: 8px !important;

  /* Übergangseffekte für sanfte Animationen */
  transition: transform 0.2s ease, box-shadow 0.2s ease !important;

  /* Optional: Padding und Margin anpassen, falls nötig */
  padding: 1rem !important;
  margin-bottom: 1rem !important;
}

.alert-warning {
  /* Bestehende Bootstrap-Variablen beibehalten und anpassen */
  --bs-alert-color: #664d03 !important; /* Dunkleres Gelb/Braun für Text */
  --bs-alert-bg: linear-gradient(
    135deg,
    #fff3cd,
    #ffeeba
  ) !important; /* Sanfter Farbverlauf von hellgelb zu cremefarben */
  --bs-alert-border-color: #ffe8a1 !important; /* Leichterer Gelbton für die Border */

  /* Hintergrund mit Farbverlauf */
  background: var(--bs-alert-bg) !important;

  /* Border anpassen */
  border: 1px solid var(--bs-alert-border-color) !important;

  /* 3D-Effekt durch Box-Shadow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;

  /* Abgerundete Ecken für ein moderneres Aussehen */
  border-radius: 8px !important;

  /* Übergangseffekte für sanfte Animationen */
  transition: transform 0.2s ease, box-shadow 0.2s ease !important;

  /* Optional: Padding und Margin anpassen, falls nötig */
  padding: 1rem !important;
  margin-bottom: 1rem !important;

  /* Textfarbe anpassen */
  color: var(--bs-alert-color) !important;

  /* Optional: Schriftart und -gewicht anpassen */
  font-weight: 500 !important;
}

.required-error {
  border: 2px solid #f1086b !important;
  border-radius: 4px;
}

/* Container für Input und Icon */
.input-icon-container {
  position: relative;
  display: inline-block; /* Passt sich dem Inhalt an */
}

/* Stil für das FontAwesome Icon */
.icon-style {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2em;
  cursor: pointer;

  /* Übergangseffekte für Interaktivität */
  transition: transform 0.3s, text-shadow 0.3s;
}

/* Hover-Effekt für das Icon */
.icon-style:hover {
  transform: translateY(-50%) scale(1.1);
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
}

/* stile für Time-Recording-Table */

.audit-history-table {
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(to bottom right, #e0f7fa5e, #e1bee73c);

  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(63, 81, 181, 0.2);
  /* 3D-Effekt durch Schatten */
  transform: perspective(800px) rotateX(1deg);
  margin: 0;
  padding: 0;
}

.audit-history-header th {
  background: rgba(255, 255, 255, 0.7);
  font-weight: bold;
  padding: 8px;
  text-align: left;
  font-size: 14px;
  border-bottom: 2px solid #ccc;
  backdrop-filter: blur(5px);
}

.audit-history-row {
  transition: transform 0.3s, background-color 0.3s;
  /* leichter Hover-Effekt */
}

.audit-history-row:hover {
  transform: scale(1.01);
  background-color: rgba(255, 255, 255, 0.1);
}

.audit-history-cell {
  padding: 8px;
  font-size: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #333;
}

/* Wenn geändert, hebe diese Zeile visuell hervor */
.changed-row {
  /* sanfter gelber Verlauf */
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}

.audit-history-container {
  max-height: 300px; /* Höhe anpassen nach Bedarf */
  overflow-y: auto; /* nur vertikaler Scroll */
  overflow-x: hidden; /* horizontalen Scroll vermeiden */
}

/* Scrollbar-Styling (funktioniert in Chrome, Safari, Edge):
   Für Firefox separate CSS-Eigenschaften nötig. */

.audit-history-container::-webkit-scrollbar {
  width: 8px; /* Breite des Scrollbalkens */
}

.audit-history-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Hintergrund des Scroll-Leisten-Tracks */
  border-radius: 4px;
}

.audit-history-container::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #70829f67, #9ab4c081);
  border-radius: 4px;
}

.audit-history-container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #8595b6c1, #839ca2a0);
}

/* stile für Time-Recording-Table END */

.fc-resource-overtime {
  text-align: right;
  font-size: 0.85em;
  float: right;
  padding-right: 15px;
  font-weight: 700;
  border-radius: 20px;
  background-color: rgba(140, 153, 205, 0.124);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #e1edff !important;
  border-radius: 15px;
}
