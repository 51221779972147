.table-wrap {
    overflow-x: scroll;
}

.table {
    min-width: 100px !important;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
}

.table thead th {
    border: none;
}

.table tbody tr {
    margin-bottom: 10px;
    -webkit-box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.29);
    box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.29);
}

.table tbody th,
.table tbody td {
    border: none;
    font-size: 14px;
    background: #fff;
}

.table tbody td .close span {
    font-size: 12px;
    color: #dc3545;
}